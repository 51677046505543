<template>
    <main class="h-full w-full overflow-y-auto">
        <div class="px-2 lg:px-4">
            <div class="flex items-center p-6 bg-gray-50 dark:bg-gray-900">
                <div class="flex-1 h-max max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                    <div class="flex flex-col overflow-y-auto md:flex-row">
                        <div class="flex items-center justify-center p-6 sm:p-12 w-full">
                            <div class="w-full">
                                <h1 class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                    Login
                                </h1>
                                <form @submit.prevent="performLogin">
                                    <label class="block text-sm">
                                        <span class="text-gray-700 dark:text-gray-400">Email</span>
                                        <input type="email" v-model="email"
                                            class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                            name="email" required>
                                    </label>
                                    <label class="block mt-4 text-sm">
                                        <span class="text-gray-700 dark:text-gray-400">Password</span>
                                        <input type="password" v-model="password"
                                            class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                            name="password" required>
                                    </label>

                                    <button type="submit"
                                        class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                                        Log in
                                    </button>
                                </form>

                                <hr class="my-8">

                                <p class="mt-1">
                                    New in here ?
                                    <router-link
                                        class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                                        :to="{ name: 'signup' }">
                                        Create account
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "loginPage",
    data() {
        return {
            email: "",
            password: "",
        };
    },
    methods: {
        ...mapActions('user', ['login']),
        async performLogin() {
            const result = await this.login({ email: this.email, password: this.password });

            if (result.success) {
                const from = this.$route.query.from || null;

                if (from) {
                    this.$router.push(from);
                } else {
                    this.$router.push({ name: 'home' });
                }
            } else {
                alert(result.error);
            }
        }

    },
};
</script>
