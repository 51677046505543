// user.js
export const namespaced = true;

export const state = {
  isLoggedIn: false,
  userName: null,
  userId: null,
};

export const mutations = {
  setUser(state, user) {
    state.isLoggedIn = true;
    state.userName = user.first_name + ' ' + user.last_name;
    state.userId = user.id;
    localStorage.setItem('user', JSON.stringify(user));
  },
  clearUser(state) {
    state.isLoggedIn = false;
    state.userName = null;
    state.id = null;
    localStorage.removeItem('user');
  },
};

export const actions = {
  async login({ commit }, { email, password }) {
    try {
      const response = await fetch("https://admin-test.bangla.express/api/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const userData = await response.json();
        commit("setUser", userData.user);
        return { success: true };
      } else {
        return { success: false, error: "Login failed. Please check your credentials and try again." };
      }
    } catch (error) {
      console.error("Error during login:", error);
      return { success: false, error: "An error occurred during login." };
    }
  },

  logout({ commit }) {
    commit("clearUser");
  },

  initializeUser({ commit }) {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      commit('setUser', userData);
    }
  },
};
