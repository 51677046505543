<template>
  <main class="h-full w-full overflow-y-auto dark:bg-gray-800">
    <div class="px-2 lg:px-4">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-2 lg:my-4">
        <div class="col-span-1 md:col-span-2 lg:col-span-3">
          <NewsCard :guiv4="this.$route.params.guiv4" ref="initialNewsCard" />

          <div class="mt-5" v-for="news in otherNews" :key="news.guiv4">
            <NewsCard :guiv4="news.guiv4" />
          </div>

          <div v-if="loading" class="text-center text-success mt-4">
            Loading more news...
          </div>
        </div>

        <div>
          <RightSidebarLatestNews />
          <RightSidebarPopularNews />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NewsCard from "@/components/NewsCard.vue";
import RightSidebarLatestNews from "@/components/right_sidebar/latest_news.vue";
import RightSidebarPopularNews from "@/components/right_sidebar/popular_news.vue";
import { useHead } from '@vueuse/head'

export default {
  name: 'NewsPage',
  setup() {

    useHead({
      // Can be static or computed
      title: 'About',
      meta: [
        {
          name: `description`,
          content: 'this about about page',
        },
      ],

    })
  },
  data() {
    return {
      otherNews: [],
      page: 1,
      loading: false,
    };
  },
  methods: {
    async checkSlug() {
      try {
        const response = await fetch('https://admin-test.bangla.express/api/checkSlugByGuiv4', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            guiv4: this.$route.params.guiv4,
            slug: this.$route.params.slug,
          }),
        });
        const data = await response.json();

        if (data.error) {
          this.$router.push({ name: 'news', params: { guiv4: this.$route.params.guiv4, slug: data.correctSlug } });
        }

      } catch (error) {
        console.error('Error checking slug:', error);
      }
    },
    async loadOtherNews() {
      try {
        this.loading = true;
        const response = await fetch('https://admin-test.bangla.express/api/getOtherNewsByGuiv4', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: this.page,
            guiv4: this.$route.params.guiv4,
          }),
        });
        const data = await response.json();
        this.otherNews = [...this.otherNews, ...data];
        this.page++;
      } catch (error) {
        console.error('Error loading news:', error);
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      const main = document.documentElement;

      if (main.scrollTop + main.clientHeight >= main.scrollHeight && !this.loading) {
        console.log('Loading more news...');
        this.loadOtherNews();
      }
    },
  },
  mounted() {
    this.checkSlug();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route.params'() {
      this.page = 1;
      this.otherNews = [];
      this.checkSlug();
    }
  },
  components: {
    NewsCard,
    RightSidebarLatestNews,
    RightSidebarPopularNews,
  },
};
</script>
