<template>
    <div v-if="tags.length" class="grid grid-cols-4 lg:grid-cols-10 justify-end lg:my-2" id="tags">
        <div class="col-span-4 lg:col-span-10 px-2 lg:p-0 mt-2 flex justify-end">
            <div
                class="tags flex overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden gap-1 pb-3 lg:pb-0 scrollbar-none">
                <div v-for="(tag, index) in tags" :key="index"
                    class="flex-wrap flex-shrink-0 p-2 rounded-md justify-center items-center text-gray-400 dark:text-gray-200 bg-gray-100 dark:bg-gray-500">
                    <router-link class="font-semibold" :to="{ name: 'search', params: { search: tag.tag } }">
                        #{{ tag.tag }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            tags: [],
        };
    },
    mounted() {
        this.loadTags();
    },
    methods: {
        async loadTags() {
            try {
                const response = await fetch('https://admin-test.bangla.express/api/get_tags', {
                    method: 'GET',
                });

                const data = await response.json();
                this.tags = data;
            } catch (error) {
                console.error('Error loading tags:', error);
            }
        },
    },
};
</script>