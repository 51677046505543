<template>
    <main>
        <div class="px-2 lg:px-4">
            <p class="text-center bg-gray-300 text-black font-bold text-3xl p-3 rounded-md mt-3" id="category_name">{{
                categoryName }}</p>

            <div id="newsContainer" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-2">
                <div v-for="(news, index) in newsList" :key="index" class="shadow-md dark:rounded-md">
                    <router-link :to="{ name: 'news', params: { guiv4: news.guiv4, slug: news.slug } }">
                        <div class="h-48">
                            <img class="rounded-t-lg object-cover w-full h-full" :src="getNewsImage(news)"
                                :alt="news.title">
                        </div>
                        <div class="bg-white dark:bg-gray-600 p-2 h-20">
                            <span class="dark:text-white">{{ $utils.limitTitle(news.title) }}</span>
                        </div>
                        <div class="bg-gray-200 p-1 grid grid-cols-12 h-8">
                            <div class="col-span-6 flex flex-row">
                                <div class="p-2">
                                    <svg class="w-3 h-3 fill-gray-600 icon icon-clock">
                                        <use xlink:href="/assets/icons.svg#icon-clock"></use>
                                    </svg>
                                </div>
                                <div>
                                    <small class="text-gray-600">
                                        {{ $utils.timeAgo(news.created_at) }} </small>
                                </div>
                            </div>
                            <div class="col-span-2 flex justify-end flex-row">
                                <div class="py-2 pr-1">
                                    <svg class="w-3 h-3 fill-purple-600 icon icon-eye">
                                        <use xlink:href="/assets/icons.svg#icon-eye"></use>
                                    </svg>
                                </div>
                                <div>
                                    <small class="text-purple-600">
                                        {{ $utils.bengaliNumber(news.views) }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-span-2 flex justify-center flex-row">
                                <div class="py-2 pr-1">
                                    <svg class="w-3 h-3 fill-blue-500 icon icon-comments">
                                        <use xlink:href="/assets/icons.svg#icon-comments"></use>
                                    </svg>
                                </div>
                                <div>
                                    <small class="text-blue-500">
                                        {{ $utils.bengaliNumber(news.comments_count) }} </small>
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div class="py-2 pr-1">
                                    <svg class="w-3 h-3 fill-green-500 icon icon-like">
                                        <use xlink:href="/assets/icons.svg#icon-like"></use>
                                    </svg>
                                </div>
                                <div>
                                    <small class="text-green-500">
                                        {{ $utils.bengaliNumber(news.likes) }} </small>
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div class="py-2 pr-1">
                                    <svg class="w-3 h-3 fill-red-500 icon icon-dislike">
                                        <use xlink:href="/assets/icons.svg#icon-dislike"></use>
                                    </svg>
                                </div>
                                <div>
                                    <small class="text-red-500">
                                        {{ $utils.bengaliNumber(news.dislikes) }} </small>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div ref="bottom"></div>
        </div>
    </main>
</template>

<script>
export default {
    name: "CategoryPage",

    data() {
        return {
            categorySlug: this.$route.params.slug,
            categoryName: null,
            newsList: [],
            page: 1,
            loading: false,
        };
    },
    watch: {
        '$route'() {
            this.categorySlug = this.$route.params.slug;
            this.categoryName = null;
            this.newsList = [];
            this.page = 1;
            this.loading = false;
            this.loadNews();
            this.setupIntersectionObserver();
        },
    },
    mounted() {
        this.loadNews();
        this.setupIntersectionObserver();
    },
    methods: {
        async loadNews() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            try {
                const response = await fetch('https://admin-test.bangla.express/api/getAllNewsByCategory', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        itemsPerPage: 8,
                        page: this.page,
                        slug: this.categorySlug,
                    }),
                });

                const data = await response.json();
                this.newsList = [...this.newsList, ...data.posts];

                if (this.page == 1) {
                    this.categoryName = data.category.category_name;
                }
                this.page++;

            } catch (error) {
                console.error('Error loading news:', error);
            } finally {
                this.loading = false;
            }
        },
        setupIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            };

            const observer = new IntersectionObserver(this.handleIntersection, options);
            observer.observe(this.$refs.bottom);
        },
        handleIntersection(entries) {
            if (entries[0].isIntersecting) {
                this.loadNews();
            }
        },
        limitTitle(title, limit = 11) {
            const words = title.split(' ');
            if (words.length > limit) {
                return words.slice(0, limit).join(' ') + '...';
            }
            return title;
        },
        getNewsImage(news) {
            return news.image && news.image.w360 ? `https://images.bangla.express/${news.image.w360}` : 'https://images.bangla.express/default-image/default-358x215.png';
        },
    },
}
</script>