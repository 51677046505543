<!-- src/App.vue -->
<template>
  <div :class="{ 'dark': darkMode }">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import { useStore } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const darkMode = computed(() => store.state.darkMode.isDarkMode);
    const siteData = reactive({
      title: `My website`,
      description: `My beautiful website`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    });

    const toggleDarkMode = () => {
      store.commit('toggleDarkMode');
    };

    return { darkMode, toggleDarkMode };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
