<template>
    <main class="h-full w-full overflow-y-auto bg-gray-100 dark:bg-gray-900">
        <div class="px-2 lg:px-4">
            <div class="flex items-center min-h-screen p-6">
                <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                    <form @submit.prevent="register" class="flex flex-col md:flex-row">
                        <!-- Left Side -->
                        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                            <div class="w-full">
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">First Name</span>
                                    <input type="text" v-model="firstName"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        placeholder="First Name" name="first_name" required />
                                </label>
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Last Name</span>
                                    <input type="text" v-model="lastName"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        placeholder="Last Name" name="last_name" required />
                                </label>
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Email</span>
                                    <input type="email" v-model="email"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        placeholder="Email" name="email" required />
                                </label>
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Password</span>
                                    <input type="password" v-model="password"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        placeholder="***************" name="password" required />
                                </label>
                            </div>
                        </div>

                        <!-- Right Side -->
                        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                            <div class="w-full">
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Phone</span>
                                    <input type="number" v-model="phone"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        placeholder="Phone" name="phone" required />
                                </label>
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Date of Birth</span>
                                    <input type="date" v-model="dob"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        name="dob" required />
                                </label>
                                <label class="block mt-4">
                                    <span class="text-gray-700 dark:text-gray-400">Gender</span>
                                    <select v-model="gender"
                                        class="block w-full h-10 pl-2 mt-1 text-sm dark:border-gray-600 bg-purple-100 dark:bg-gray-700"
                                        name="gender" required>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Others</option>
                                    </select>
                                </label>

                                <div class="flex mt-6 text-sm">
                                    <label class="flex items-center dark:text-gray-400">
                                        <input type="checkbox"
                                            class="text-purple-600 form-checkbox focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray" />
                                        <span class="ml-2">
                                            I agree to the
                                            <span class="underline text-purple-600">privacy policy</span>
                                        </span>
                                    </label>
                                </div>

                                <!-- Button -->
                                <button type="submit"
                                    class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                                    Create account
                                </button>

                                <hr class="my-8" />

                                <p class="mt-4">
                                    Already have an account?
                                    <router-link
                                        class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                                        :to="{ name: 'login' }">
                                        Login Here
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
  
<script>
export default {
    name: "SignUp",
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phone: "",
            dob: "",
            gender: "1",
        };
    },
    methods: {
        async register() {
            try {
                const data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                    dob: this.dob,
                    gender: this.gender,
                };

                const response = await fetch("https://admin-test.bangla.express/api/signup", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });

                if (response.ok) {
                    const responseData = await response.json();
                    alert(responseData.message);
                    this.$router.push({ name: 'login' });
                } else {
                    alert('Email is already  in use. Try Again !');
                }
            } catch (error) {
                console.error("An error occurred", error);
            }
        },
    },
};
</script>