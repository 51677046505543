// src/store/index.js

import { createStore } from 'vuex';
import { state as darkModeState, mutations as darkModeMutations } from '@/store/darkMode.js';
import { state as homePageStyleState, mutations as homePageStyleMutations, actions as homePageStyleActions } from '@/store/homePageStyle.js';
import { namespaced as userNamespaced, state as userState, mutations as userMutations, actions as userActions } from '@/store/user.js';

export default createStore({
  modules: {
    darkMode: {
      state: darkModeState,
      mutations: darkModeMutations,
    },
    homePageStyle: {
      namespaced: true,
      state: homePageStyleState,
      mutations: homePageStyleMutations,
      actions: homePageStyleActions,
    },
    user: {
      namespaced: userNamespaced,
      state: userState,
      mutations: userMutations,
      actions: userActions,
    },
  },
});
