// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import Category from '@/components/Category.vue';
import Subcategory from '@/components/Subcategory.vue';
import News from '@/components/News.vue';
import Search from '@/components/Search.vue';
import LatestNews from '@/components/LatestNews.vue';
import PopularNews from '@/components/PopularNews.vue';
import Login from '@/components/Login.vue';
import Signup from '@/components/Signup.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'বাংলা এক্সপ্রেস | বাংলা নিউজ পেপার' },
  },
  {
    path: '/:slug',
    name: 'category',
    component: Category,
  },
  {
    path: '/:category/:slug',
    name: 'sub-category',
    component: Subcategory,
  },
  {
    path: '/news/:guiv4/:slug',
    name: 'news',
    component: News,
    meta: { title: 'news | বাংলা নিউজ পেপার' },
  },
  {
    path: '/search/:search',
    name: 'search',
    component: Search,
  },
  {
    path: '/latest-news',
    name: 'latest-news',
    component: LatestNews,
  },
  {
    path: '/popular-news',
    name: 'popular-news',
    component: PopularNews,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'বাংলা এক্সপ্রেস';
  next();
});


export default router;
